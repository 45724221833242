<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="article.articleDate"
                        label="Choose article date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        clearable
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="article.articleDate"
                    :model-config="modelConfig"
                    class="datepicker"
                    mode="dateTime"
                />
            </v-menu>
            <v-text-field
                v-model="article.title"
                :rules="rules"
                label="Title"
                :clearable="clearable"
            />
            <div class="body-editor">
                <span class="body-label">Body</span>
                <editor
                    v-model="article.body"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-text-field
                v-model="article.announcementTitle"
                label="Announcement Title"
                :clearable="clearable"
            />
            <v-text-field
                v-model="article.titleEn"
                label="Title in English"
                :clearable="clearable"
            />
            <div class="body-editor">
                <span class="body-label">Body in English</span>
                <editor
                    id="article_content"
                    v-model="article.bodyEn"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-file-input
                v-if="mode !== 'show'"
                v-model="newImage"
                label="Image input"
                :rules="imageRules"
                filled
                show-size
                prepend-icon="mdi-camera"
                @change="createNewImagePreview"
            />
            <div v-if="previewImage && !previewImage.shouldDelete" class="image-input" :class="{'green-border': !previewImage.id}">
                <div v-if="mode === 'edit'" class="delete-btn">
                    <v-btn class="mx-2 white--text" small color="red" @click="onImageDelete">
                        X
                    </v-btn>
                </div>
                <v-img v-if="previewImage.url" class="img-preview" :src="previewImage.url" />
                <v-text-field
                    v-model="previewImage.description"
                    label="Image Description"
                    :clearable="clearable"
                />
            </div>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import imageService from '@/services/image-service'
    import articleService from '@/services/article-service'
    import Editor from '@tinymce/tinymce-vue'
    import moment from 'moment'

    export default {
        name: 'ArticleForm',
        components: { Editor },
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                available: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                article: {
                    title: '',
                    body: '',
                    articleDate: null
                },
                editorInit: {
                    selector: 'article_content',
                    images_upload_handler: function(file, success) {
                        let formData = new FormData()
                        formData.append('file', file.blob(), file.filename())
                        imageService.uploadNewImage(formData).then((response) => { success(process.env.VUE_APP_API_HOST_DOMAIN + '/uploads/' + response.data.image_url) })
                    },
                    menubar: false,
                    plugins: [
                        'advlist autolink lists charmap print preview anchor',
                        'searchreplace visualblocks fullscreen',
                        'insertdatetime table paste help wordcount link',
                        'image media'
                    ],
                    automatic_uploads: true,
                    powerpaste_allow_local_images: true,
                    file_picker_types: 'image',
                    resize: 'both',
                    min_height: 600,
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect fontsizeselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link unlink | image media '
                },
                articleId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                newImage: null,
                previewImage: null,
                imageRules: [
                    value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!'
                ],
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                }
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            }
            this.articleId = this.$route.params.articleId
            if (this.articleId) {
                articleService.getArticleById(this.articleId).then(resp => {
                    this.article = resp.data.article
                    this.previewImage = this.article.image
                    if (this.previewImage) {
                        this.previewImage.url = this.apiUrl + '/uploads/images/' + this.previewImage.imageName
                    }
                    this.article.articleDate = this.formatDate(resp.data.article.articleDate, 'yyyy-MM-DD')
                })
            }
        },
        methods: {
            formatDate(date, format) {
                if (!date) {
                    return null
                }
                return moment(date).format(format)
            },
            shouldDisableSubmit() {
                return !this.article.title
            },
            onEdit() {
                this.$router.push(`/articles/${this.articleId}/edit`)
            },
            async createArticle() {
                const response = await articleService.createArticle(this.article)
                if (response.data.id) {
                    this.articleId = response.data.id
                    this.uploadImage()
                }
                this.$router.push(`/articles`)
            },
            async editArticle() {
                await articleService.editArticleById(this.articleId, this.article)
                this.uploadImage()
                this.$router.push(`/articles`)
            },
            async onSubmit() {
                this.deleteUnecessaryFields()
                if (this.mode === 'edit') {
                    this.editArticle()
                } else if (this.mode === 'create') {
                    this.createArticle()
                }
                this.deleteArticleImage()
                this.editImageDescriptions()
            },
            deleteArticleImage() {
                if (this.previewImage.id && this.previewImage.shouldDelete) {
                    imageService.deleteImageById(this.previewImage.id)
                    this.previewImage = null
                }
            },
            createNewImagePreview() {
                this.previewImage = this.newImage
                this.previewImage.url = URL.createObjectURL(this.newImage)
                this.previewImage.description = null
            },
            deleteUnecessaryFields() {
                delete this.article.id
                delete this.article.image
                delete this.article.createdAt
            },
            uploadImage() {
                if (!this.newImage || (this.newImage && this.newImage.id)) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.newImage)
                formData.append('articleId', this.articleId)
                if (this.newImage.description) {
                    formData.append('description', this.newImage.description)
                }
                imageService.uploadNewArticleImage(formData)
            },
            editImageDescriptions() {
                if (this.previewImage && this.previewImage.id) {
                    let formData = new FormData()
                    formData.append('articleId', this.articleId)
                    if (this.previewImage.description) {
                        formData.append('description', this.previewImage.description)
                    }
                    imageService.editImageById(this.previewImage.id, formData)
                }
            },
            onImageDelete() {
                if (this.previewImage.id) {
                    this.previewImage.shouldDelete = true
                    this.previewImage.url = null
                    this.previewImage = { ...this.previewImage }
                } else {
                    this.newImage = null
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
