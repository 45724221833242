import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import jwtUtil from '@/utils/jwtUtil'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/routes/HomeRoute')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/routes/LoginRoute')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import('@/routes/UserRoute')
    },
    {
        path: '/articles',
        name: 'Articles',
        component: () => import('@/routes/ArticleRoute')
    },
    {
        path: '/articles/:articleId/:mode',
        name: 'Article Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/users/:userId/:mode',
        name: 'User Form',
        component: () => import('@/forms/UserForm')
    },
    {
        path: '/articles/:mode',
        name: 'Create Article Form',
        component: () => import('@/forms/ArticleForm')
    },
    {
        path: '/users/:mode',
        name: 'Create User Form',
        component: () => import('@/forms/UserForm')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const currentUser = store.getters.currentUser
    const token = store.getters.getToken
    if ((!token || (token && jwtUtil.isJwtExpired(token))) && to.name !== 'Login') {
        store.dispatch('logout').then(() => next({ path: '/login' }))
    } else if (!currentUser && to.name === 'Login') {
        next()
    } else if (currentUser.role === 'admin' || (to.meta.roles && to.meta.roles.includes(currentUser.role))) {
        next()
    }
})

export default router
