<template>
    <v-navigation-drawer
        color="white"
        permanent
        absolute
        expand-on-hover
        style="z-index:10000;"
    >
        <v-list>
            <v-list-item class="px-2">
                <v-img src="@/assets/main_logo.png" />
            </v-list-item>
        </v-list>
        <v-list
            nav
            dense
        >
            <v-list-item link @click="logout">
                <v-list-item-icon>
                    <v-icon color="#213050">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-divider />
            <div v-for="(route, index) of routes" :key="index">
                <v-list-item v-if="route.roles.length === 0 || route.roles.includes(userRole)" :to="route.to" link>
                    <v-list-item-icon>
                        <v-icon color="#213050">mdi-{{ route.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ route.title }}</v-list-item-title>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import store from '@/store'
    export default {
        name: 'Sidebar',
        data() {
            return {
                routes: [
                    {
                        title: 'Articles',
                        to: '/articles?page=1&per_page=10',
                        icon: 'newspaper-variant-outline',
                        roles: ['admin']
                    }
                    // {
                    //     title: 'Users',
                    //     to: '/users?page=1&per_page=10',
                    //     icon: 'account-edit',
                    //     roles: ['admin']
                    // },
                    // {
                    //     title: 'About Us',
                    //     to: '/about-us',
                    //     icon: 'information-outline',
                    //     roles: ['admin']
                    // }
                ]
            }
        },
        computed: {
            userRole() {
                return store.getters.currentUser && store.getters.currentUser.role
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>

</style>
